declare namespace Intl {
  function getCanonicalLocales(locales: string | string[]): string[]
}

export const getCanonicalLocalName = (locale: string = 'en', defaultLocale = 'en-AU') => {
  let loc = locale.trim()
  try {
    const canonicalLocales = Intl.getCanonicalLocales(loc.replace(/_/g, '-'))
    if (canonicalLocales && canonicalLocales.length) {
      const [first] = canonicalLocales
      loc = first
    } else {
      loc = defaultLocale
    }
  } catch {
    loc = defaultLocale
  }

  return loc
}

/**
   * Adjusts the chinese locale string.
   * @param locale - the lcoale code to be adjusted.
   * @returns {string} - Locale code.
   *
   */
export const correctLocale = (locale: string): string => {
  let loc = locale.trim()
  if (loc === 'zh-TW') loc = 'zh-Hant'
  else if (loc === 'zh-CN') loc = 'zh-Hans'
  else if (loc === 'th') loc = 'th-TH-u-ca-gregory'
  else if (loc === 'en') loc = 'en-AU'
  else loc = getCanonicalLocalName(locale)

  return loc
}
