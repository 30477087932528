import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExclamationTriangle, faCalendar, faAngleLeft, faAngleRight, faTimes, faPlus, faMinus, faBed } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vClickOutside from 'v-click-outside'
import focus from './directives/focus'

Vue.use(vClickOutside)

Vue.directive('focus', focus)

Vue.component('FontAwesomeIcon', FontAwesomeIcon)

library.add(faExclamationTriangle, faCalendar, faAngleLeft, faAngleRight, faTimes, faPlus, faMinus, faBed)

// Load the Noto Sans font externally
const linkNode = document.createElement('link')
linkNode.type = 'text/css'
linkNode.rel = 'stylesheet'
linkNode.href = '//fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap'
document.head.appendChild(linkNode)
