const paddedMonth = (date: Date) => (date.getMonth() + 1).toString().padStart(2, '0')

const paddedDate = (date: Date) => date.getDate().toString().padStart(2, '0')

const formatDate = (date: Date) => `${date.getFullYear()}-${paddedMonth(date)}-${paddedDate(date)}`

const addOneDay = (date: Date) => {
  const dateValue = new Date(date)
  return new Date(dateValue.setDate(dateValue.getDate() + 1))
}

export {
  formatDate,
  addOneDay,
}
