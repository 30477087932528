import { Spacetime } from 'spacetime'
import isValid, { SpacetimeValidity } from '../isValid'

/**
 * Returns an array of Dates between 2 dates.
 */
export default (start: Date|string|number, end: Date|string|number): Array<Date> => {
  const startValidity: SpacetimeValidity = isValid(start)
  const endValidity: SpacetimeValidity = isValid(end)

  if (!startValidity.isValid || !endValidity.isValid) {
    return []
  }

  const d1: Spacetime = startValidity.spacetime!
  const diffInDays = d1.diff(end, 'day')

  const result: Date[] = []

  for (let i = 0; i <= diffInDays; i += 1) {
    result.push(d1.add(i, 'day').d)
  }
  return result
}
