import { Spacetime } from 'spacetime'
import isValid, { SpacetimeValidity } from '../isValid'

export default (date1: Date | string | number, date2: Date | string | number): boolean => {
  const date1Validity: SpacetimeValidity = isValid(date1)
  const date2Validity: SpacetimeValidity = isValid(date2)
  if (!date1Validity.isValid || !date2Validity.isValid) {
    return false
  }

  const d1: Spacetime = date1Validity.spacetime!
  const d2: Spacetime = date2Validity.spacetime!

  return d1.isBefore(d2)
}
