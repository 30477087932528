import normalizeDate from '../services/DateTimeService/normalizeDate'
import { CalendarItem } from '../types'

class DateHelper {
  private Date: Date

  constructor(str: number | string = Date.now()) {
    this.Date = normalizeDate(str)
    this.Date.setHours(0, 0, 0, 0)
  }

  addMonths(months: number) {
    this.Date.setMonth(this.Date.getMonth() + months)
  }
  addDays(days: number) {
    this.Date.setDate(this.Date.getDate() + days)
  }
  addWeeks(weeks: number) {
    this.addDays(weeks * 7)
  }

  get year(): string {
    return `${this.Date.getFullYear()}`
  }

  get month(): string {
    const m = this.Date.getMonth() + 1
    return m < 10 ? `0${m}` : `${m}`
  }

  get day(): string {
    const d = this.Date.getDate()
    return d < 10 ? `0${d}` : `${d}`
  }
  get dayOfWeek(): number {
    return this.Date.getDay()
  }

  get time():number {
    return this.Date.getTime()
  }

  get date(): string {
    return `${this.year}-${this.month}-${this.day}`
  }
}

export interface Day {
  isToday?: boolean
  year?: number
  month?: number
  day?: number
  date?: string // YYYY-MM-DD format
  disabled?: boolean
  unavailable?: boolean
  active?: boolean
  begin?: boolean
  end?: boolean
  ariaLabel?: string
  pos?: [number, number]
  single?: boolean
}

export interface Month {
  year: number
  month: number
  days: Array<CalendarItem>
  label: string
}

export default DateHelper
