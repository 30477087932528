import { computed } from 'vue'
import { changeColorBrightness, getBlackOrWhiteContrastColor } from '@/utils/css-utils'
import { CustomStyle, SmMpIbeWidgetConfig } from '@/types'
import useWidgetConfig from './use-widget-config'

export const darker50 = -50
export const lighter25 = 25
export const darker40 = -40
export const whiteColor = '#ffffff'
export const nearWhiteColor = '#ebebeb'
export const nearBlackColor = '#0f0f0f'
export const semiTransBlack = 'rgba(5,5,5,.97)'
export const semiTransWhite = 'rgba(250,250,250,.97)'
export const nearWhiteButtonBackgroundColor = '#fafafa'

const colorThemeStyles = (smMpIbeWidgetConfig: SmMpIbeWidgetConfig): Record<string, CustomStyle> => ({
  light: {
    buttonFontColor: getBlackOrWhiteContrastColor(smMpIbeWidgetConfig.highlightColor) === whiteColor ? whiteColor : nearBlackColor,
    buttonBackgroundColor: smMpIbeWidgetConfig.highlightColor,
    buttonBorderColor: changeColorBrightness(smMpIbeWidgetConfig.highlightColor, darker50),
    fieldBorderColor: changeColorBrightness(smMpIbeWidgetConfig.highlightColor, darker50),
    widgetBorderColor: smMpIbeWidgetConfig.isIntegratedTagExisting ? changeColorBrightness(smMpIbeWidgetConfig.highlightColor, darker50) : nearBlackColor,
    widgetBackgroundColor: semiTransWhite,
    fontColor: nearBlackColor,
  },
  dark: {
    buttonFontColor: getBlackOrWhiteContrastColor(smMpIbeWidgetConfig.highlightColor) === whiteColor ? whiteColor : nearBlackColor,
    buttonBackgroundColor: smMpIbeWidgetConfig.highlightColor,
    buttonBorderColor: changeColorBrightness(smMpIbeWidgetConfig.highlightColor, darker50),
    fieldBorderColor: changeColorBrightness(smMpIbeWidgetConfig.highlightColor, lighter25),
    widgetBorderColor: smMpIbeWidgetConfig.isIntegratedTagExisting ? smMpIbeWidgetConfig.highlightColor : nearBlackColor,
    widgetBackgroundColor: semiTransBlack,
    fontColor: nearWhiteColor,
  },
  colorful: getColorfulStyle(smMpIbeWidgetConfig),
})

const useCustomStyles = (configOverride?: SmMpIbeWidgetConfig) => computed(
  () => {
    const config = configOverride ?? useWidgetConfig().smMpIbeWidgetConfig

    return colorThemeStyles(config)[config.backgroundColor]
  },
)

export {
  useCustomStyles,
}

const getNearBlackOrWhiteContrastColor = (color: string) => (getBlackOrWhiteContrastColor(color) === whiteColor ? whiteColor : nearBlackColor)

const getColorfulStyle = (smMpIbeWidgetConfig: SmMpIbeWidgetConfig) => {
  const widgetBackgroundColor = smMpIbeWidgetConfig.isIntegratedTagExisting
    ? changeColorBrightness(smMpIbeWidgetConfig.highlightColor, darker40)
    : smMpIbeWidgetConfig.highlightColor

  return {
    buttonFontColor: smMpIbeWidgetConfig.isIntegratedTagExisting
      ? getNearBlackOrWhiteContrastColor(widgetBackgroundColor)
      : nearBlackColor,
    buttonBackgroundColor: smMpIbeWidgetConfig.isIntegratedTagExisting
      ? changeColorBrightness(smMpIbeWidgetConfig.highlightColor, lighter25)
      : nearWhiteButtonBackgroundColor,
    buttonBorderColor: smMpIbeWidgetConfig.isIntegratedTagExisting ? nearWhiteColor : changeColorBrightness(smMpIbeWidgetConfig.highlightColor, darker50),
    fieldBorderColor: smMpIbeWidgetConfig.isIntegratedTagExisting
      ? widgetBackgroundColor
      : changeColorBrightness(smMpIbeWidgetConfig.highlightColor, darker50),
    widgetBorderColor: smMpIbeWidgetConfig.isIntegratedTagExisting
      ? changeColorBrightness(smMpIbeWidgetConfig.highlightColor, darker40)
      : changeColorBrightness(smMpIbeWidgetConfig.highlightColor, darker50),
    widgetBackgroundColor,
    fontColor: getNearBlackOrWhiteContrastColor(widgetBackgroundColor),
  }
}
