/* eslint-disable */
import spacetime, { ParsableDate } from 'spacetime'
import { correctLocale } from '../services/i18nSettings.service'

/**
 * Parses a time string and returns a date object.
 * @param t time - eg. 11:00 PM
 * @returns {string} - returns a Date object
 */
export function parseTime(t: string) {
  let d = new Date()
  let time = t.match(/(\d+)(?::(\d\d))?\s*([pP]?)/)
  d.setUTCHours(parseInt(time![1]) + (time![3] ? 12 : 0))
  d.setUTCMinutes(parseInt(time![2]) || 0)
  return d
}

/**
 * Formats date string into a time. Useful for things like Arrival Time
 * @param time - DateTime format. eg. new Date(YYYY-MM-DDTHH:mm)
 * @param locale - locale code that matches a key in @/lang/dateTimeFormats
 */
export function time(time: string, locale: string) {
  if (!!time && !!locale) {
    const t = parseTime(time)
    const options = { hour: 'numeric', minute: 'numeric', timeZone: 'UTC' } as Intl.DateTimeFormatOptions
    const loc = correctLocale(locale)

    return t.toLocaleTimeString(loc, options)
  }
}

export const DATE_FORMAT = { month: 'short', day: 'numeric', weekday: 'short' }

export const SHORT_DATE_FORMAT = { month: 'short', day: 'numeric' }

/**
 * Formats a date string into a localized date
 * @param date - Date string to be parsed by date-fns
 * @param locale - locale code that matches a key in @/lang/dateTimeFormats
 */
export function date(date: string | Date, locale: string, options?: Intl.DateTimeFormatOptions) {
  if (!!date && !!locale) {
    if (!options) {
      options = { ...DATE_FORMAT, timeZone: 'UTC' } as Intl.DateTimeFormatOptions
    }
    const spaceDate = spacetime(date as ParsableDate)
    const loc = correctLocale(locale)

    return spaceDate.toLocalDate().toLocaleDateString(loc, options)
  }
}

/**
 * Builds a date format function that is already configured
 * @param locale - locale code that matches a key in @/lang/dateTimeFormats
 * @param options - Options for formatting (Intl.DateTimeFormatOptions)
 */
export const dateFormatter = (locale: string, options?: Intl.DateTimeFormatOptions): ((date: string | Date) => string) => {
  const localDate = new Date()
  options = options || {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    weekday: 'short',
    timeZone: 'UTC'
  }
  const loc = correctLocale(locale)
  const formatter = new Intl.DateTimeFormat(loc, options)
  return (date: string | Date): string => {
    // have removed locale check here, as we should rely on tsc
    if (date) {
      if (typeof date === 'string') {
        const dateSplit = date.split('-').map(val => {
          return parseInt(val)
        })
        localDate.setUTCFullYear(dateSplit[0], dateSplit[1] - 1, dateSplit[2])
      } else {
        return formatter.format(date)
      }
      localDate.setUTCHours(0, 0, 0, 0)

      return formatter.format(localDate)
    }
    return ''
  }
}

/**
 * Formats a date string intoi a localized date
 * @param date - Date string to be parsed by date-fns
 * @param locale - locale code that matches a key in @/lang/dateTimeFormats
 */
export function getLocaleDateString(date: string | Date, locale: string, options?: Intl.DateTimeFormatOptions) {
  if (!!date && !!locale) {
    let formatOptions: Intl.DateTimeFormatOptions
    if (!options) {
      formatOptions = { ...DATE_FORMAT, timeZone: 'UTC' } as Intl.DateTimeFormatOptions
    }
    // ensure timeZone is UTC
    formatOptions = {
      ...options,
      timeZone: 'UTC'
    }
    // construct the spacetime based on the specified timezoneoffset
    const spaceDate = spacetime(date as ParsableDate)
    // touch up the locale
    const loc = correctLocale(locale)

    // spacetime does not give us method to return an utc date, so create an UTC date using native javascript Date
    const UTCDate = new Date(Date.UTC(spaceDate.year(), spaceDate.month(), spaceDate.date()))

    // get the date in locale string for displaying
    const theDateInLocale = UTCDate.toLocaleDateString(loc, formatOptions)

    return theDateInLocale
  }
}