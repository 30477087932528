const hexToDec = (hexString: string) => parseInt((hexString).replace(/[^a-f0-9]/gi, ''), 16)

const changeColorBrightness = (color: string, amount: number): string => {
  return `#${color.replace(/^#/, '').replace(/../g, c => (`0${Math.min(255, Math.max(0, parseInt(c, 16) + amount)).toString(16)}`).substr(-2))}`
}

const getBlackOrWhiteContrastColor = (color: string): string => {
  const whiteColor = '#ffffff'
  const blackColor = '#000000'
  const red = hexToDec(color.substr(1, 2))
  const green = hexToDec(color.substr(3, 2))
  const blue = hexToDec(color.substr(5, 2))

  const contrast = Math.sqrt(
    red * red * 0.241
    + green * green * 0.691
    + blue * blue * 0.068,
  )

  return contrast > 130 ? blackColor : whiteColor
}

const convertHexToRgb = (hexCode: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode)
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16),
  } : {
    r: 0,
    g: 0,
    b: 0,
  }
}

export {
  getBlackOrWhiteContrastColor,
  changeColorBrightness,
  convertHexToRgb,
}
