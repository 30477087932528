/* istanbul ignore file */
const getUrlSearchParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search)

  return Object.fromEntries(urlSearchParams.entries())
}

export {
  getUrlSearchParams,
}
