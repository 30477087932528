import { render, staticRenderFns } from "./property-picker.vue?vue&type=template&id=28ee4342&scoped=true&"
import script from "./property-picker.vue?vue&type=script&setup=true&lang=ts&"
export * from "./property-picker.vue?vue&type=script&setup=true&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./property-picker.vue?vue&type=style&index=0&id=28ee4342&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "28ee4342",
  null
  ,true
)

export default component.exports