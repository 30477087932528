var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(() => _setup.shouldShowForm = false),expression:"() => shouldShowForm = false"}]},[_c('button',{staticClass:"sm-ibe-input-field",class:[_vm.fieldShapeClass, _vm.fieldColorClass, _vm.buttonColorClass],style:({ 'border-color': _vm.fieldBorderColor }),attrs:{"id":"guests"},on:{"click":_setup.toggleForm}},[_c('font-awesome-icon',{staticClass:"sm-ibe-guest-picker__bed-icon",attrs:{"icon":"bed"}}),_c('span',{staticClass:"sm-ibe-guest-picker__minimal-input"},[_vm._v(" "+_vm._s(_setup.guestPickerSummaryPlaceholder)+" ")])],1),(_setup.shouldShowForm)?_c('div',{staticClass:"sm-ibe-guest-picker__form",class:[(_vm.isDropdown) ? 'sm-ibe-guest-picker__form--is-dropdown' : 'sm-ibe-guest-picker__form--is-above', _vm.guestPickerFormColorClass, _vm.fieldShapeClass],style:({ 'border-color': _vm.popupStyles.fieldBorderColor })},[_c('div',{staticClass:"sm-ibe-guest-picker__form__input-group"},[_c('span',{style:({
          'font-weight': '600',
        })},[_vm._v(" "+_vm._s(_setup.t('numberOfAdultsLabel'))+" ")]),_c(_setup.MpSteppers,{attrs:{"initial-value":_setup.adultCount,"min-value":1,"max-value":10,"label":"Adults","popup-styles":_vm.popupStyles},on:{"updateValue":_setup.updateAdultCount}})],1),_c('div',{staticClass:"sm-ibe-guest-picker__form__input-group"},[_c('span',{style:({
          'font-weight': '600',
        })},[_vm._v(" "+_vm._s(_setup.t('numberOfChildrenLabel'))+" ")]),_c('br'),_c(_setup.MpSteppers,{attrs:{"initial-value":_setup.childrenCount,"min-value":0,"max-value":10,"label":"Children","popup-styles":_vm.popupStyles},on:{"updateValue":_setup.updateChildrenCount}})],1),_c('div',{staticClass:"sm-ibe-guest-picker__form__button-group"},[_c('button',{staticClass:"sm-ibe-guest-picker__form__button sm-ibe-button",class:[_vm.fieldShapeClass, _vm.buttonColorClass],style:({
          'color': _vm.buttonFontColor,
          'background-color': _setup.doneButtonColor,
          'border-color': _vm.popupStyles.fieldBorderColor,
          'border': `1px solid ${_vm.popupStyles.buttonBorderColor}`,
          'font-weight': 'bold',
          'font-size': '0.875rem',
          'padding': '5px 15px',
          'cursor': 'pointer',
          'width': '85px',
          'height': '36px',
        }),attrs:{"type":"submit"},on:{"mouseover":function($event){_setup.buttonHover = true},"mouseleave":function($event){_setup.buttonHover = false},"click":_setup.formSubmit}},[_vm._v(" "+_vm._s(_setup.t('guestPickerConfirmationButtonText'))+" ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }