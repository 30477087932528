import spacetime, { Spacetime } from 'spacetime'

export default (dateString: string): Spacetime => {
  const oldParserRegex = /^([0-2][0-9]|(3)[0-1])([-/])(((0)[0-9])|((1)[0-2]))([-/])\d{4}$/i // dd-MM-yyyy
  const oldParserAmericanRegex = /^(((0)[0-9])|((1)[0-2]))([-/])([0-2][0-9]|(3)[0-1])([-/])\d{4}$/i // MM-dd-yyyy
  if (!dateString) return spacetime.now()
  let dateParts = dateString.split('-')
  if (dateParts.length === 1) {
    dateParts = dateString.split('/')
  }
  if (dateParts.length > 1 && dateParts.length !== 3) return spacetime.now()

  let date: Spacetime | null = null

  try {
    if (oldParserRegex.test(dateString)) {
      // month is 0-based, that's why we need dataParts[1] - 1
      date = spacetime(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`)
    } else if (oldParserAmericanRegex.test(dateString)) {
      date = spacetime(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`)
    } else if (!Number.isNaN(Date.parse(dateString))) {
      date = spacetime(dateString)
    }
    if (date === null) return spacetime.now()

    return date
  } catch (_ex) {
    return spacetime.now()
  }
}
