/** Takes a valid date string and returns a Date object as is, without timezones converting etc */
import isValid, { SpacetimeValidity } from '../isValid'

export default (date:string|Date|number): Date => {
  const validity: SpacetimeValidity = isValid(date)
  if (!validity.isValid) {
    return new Date(NaN)
  }

  return validity.spacetime!.d
}
