import { Spacetime } from 'spacetime'
import isValid, { SpacetimeValidity } from '../isValid'

export default (test: Date | string | number, start: Date | string | number, end: Date | string | number): boolean => {
  const testValidity: SpacetimeValidity = isValid(test)
  const startValidity: SpacetimeValidity = isValid(start)
  const endValidity: SpacetimeValidity = isValid(end)

  if (!testValidity.isValid || !startValidity.isValid || !endValidity.isValid) {
    return false
  }

  const testDate: Spacetime = testValidity.spacetime!
  const startDate: Spacetime = startValidity.spacetime!
  const endDate: Spacetime = endValidity.spacetime!

  return testDate.isBetween(startDate, endDate, true)
}
