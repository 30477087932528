import { computed } from 'vue'
import useWidgetConfig from './use-widget-config'

const { smMpIbeWidgetConfig, widgetRenderMode } = useWidgetConfig()
const fieldShapeClass = computed(() => `--field-shape-${smMpIbeWidgetConfig.buttonShape}`)
const fieldColorClass = computed(() => `sm-ibe-input-field--color-${smMpIbeWidgetConfig.backgroundColor}`)
const buttonColorClass = computed(() => `sm-ibe-button--color-${smMpIbeWidgetConfig.backgroundColor}`)
const buttonTextColorClass = computed(() => `sm-ibe-button-text--color-${smMpIbeWidgetConfig.backgroundColor}`)
const widgetColorClass = computed(() => `sm-ibe-base-widget--color-${smMpIbeWidgetConfig.backgroundColor}`)
const guestPickerFormColorClass = computed(() => `sm-ibe-guest-picker__form--color-${smMpIbeWidgetConfig.backgroundColor}`)
const displayModeClass = computed(() => `sm-ibe-base-widget--${widgetRenderMode.value}`)

export default () => ({
  fieldShapeClass,
  fieldColorClass,
  buttonColorClass,
  buttonTextColorClass,
  widgetColorClass,
  displayModeClass,
  guestPickerFormColorClass,
})
