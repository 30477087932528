export enum IbeWidgetBackgroundColor {
  light = 'light',
  dark = 'dark',
  colorful = 'colorful'
}

export enum IbeWidgetButtonShape {
  curved = 'curved',
  square = 'square',
  rounded = 'rounded'
}

export enum IbeWidgetRenderMode {
  overlaid = 'overlaid',
  integrated = 'integrated',
  none = 'none'
}

export enum events {
  FirstSelect = 'start',
  CompleteSelect = 'complete',
  Navigate = 'navigate',
  Close = 'close',
  Reset = 'reset'
}

export interface SmMpIbeWidgetConfig {
  widgetId: string
  isIntegratedTagExisting: boolean
  overlaidEnabled: boolean
  integratedEnabled: boolean
  backgroundColor: IbeWidgetBackgroundColor
  highlightColor: string
  buttonShape: IbeWidgetButtonShape
  contentWidth: number | null
  mobileViewBreakpoint: number | null
  iframeEnabled: boolean
  isMobile: boolean
  isTablet: boolean
}

export interface Config {
  buttonShape: string
  highlightColor: string
  integratedBackgroundColor: string
  integratedEnabled: boolean
  overlaidBackgroundColor: string
  overlaidEnabled: boolean
  iframeEnabled: boolean
}

export interface CustomStyle {
  buttonFontColor: string
  buttonBackgroundColor: string
  buttonBorderColor: string
  fieldBorderColor: string
  widgetBorderColor: string
  widgetBackgroundColor: string
  fontColor: string
}

export interface Property {
  spid: string
  name: string
  bookingEngineLink: string
}

export interface PropertyPickerEvent {
  bookingEngineLink: string
  spid: string
}

export interface FormValues {
  bookingEngineLink: string | undefined
  checkInDate: string
  checkOutDate: string
  guests: {
    adults: number
    children: number
  }
}

export interface MpStepperValue {
  adults: number
  children: number
}
