import { render, staticRenderFns } from "./mp-steppers.vue?vue&type=template&id=5828f69e&scoped=true&"
import script from "./mp-steppers.vue?vue&type=script&setup=true&lang=ts&"
export * from "./mp-steppers.vue?vue&type=script&setup=true&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./mp-steppers.vue?vue&type=style&index=0&id=5828f69e&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5828f69e",
  null
  ,true
)

export default component.exports