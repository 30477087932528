import '@/initialize-vue'
import { Ref } from 'vue'
import { IbeWidgetBackgroundColor, IbeWidgetButtonShape, Config } from '@/types'
import { getUrlSearchParams } from '@/utils/get-url-search-params'
import { changeColorBrightness } from '@/utils/css-utils'
import { darker50 } from '@/composables/use-custom-styles'
import useWidgetConfig from '@/composables/use-widget-config'
import { loadLanguageAsync } from './i18n'
import { deriveBeefUrl, fetchPropertyPickerConfig, usePropertyPicker } from './property-pickers'

declare global {
  interface Window {
    initializeSmMpIbeWidget: typeof initializeSmMpIbeWidget
    ga: any
    gaplugins: any
  }
}

const { smMpIbeWidgetConfig, setSmMpIbeWidgetConfig } = useWidgetConfig()
let overlaidBackgroundColor = smMpIbeWidgetConfig.backgroundColor
let integratedBackgroundColor = smMpIbeWidgetConfig.backgroundColor

const embedSmMpIbeWidget = () => {
  const widgetElement = document.createElement('sm-mp-ibe-widget')
  widgetElement.setAttribute('sm-mp-ibe-overlaid', 'true')
  document.body.appendChild(widgetElement)
}

const getIntegratedModeTagElement = () => {
  const element = document.querySelector('sm-mp-ibe-widget')

  if (!element) {
    return null
  }

  if (element.hasAttribute('sm-mp-ibe-overlaid')) {
    return null
  }

  return element
}

const fetchConfigFromParams = (params: { [k: string]: string }, config: Ref<Config | undefined>) => {
  overlaidBackgroundColor = IbeWidgetBackgroundColor[params.smMpOverlaidBackgroundColor as keyof typeof IbeWidgetBackgroundColor]
    ?? config.value?.overlaidBackgroundColor ?? smMpIbeWidgetConfig.backgroundColor
  integratedBackgroundColor = IbeWidgetBackgroundColor[params.smMpIntegratedBackgroundColor as keyof typeof IbeWidgetBackgroundColor]
    ?? config.value?.integratedBackgroundColor ?? smMpIbeWidgetConfig.backgroundColor

  setSmMpIbeWidgetConfig({
    overlaidEnabled: params.smMpOverlaidEnabled !== undefined
      ? params.smMpOverlaidEnabled === 'true'
      : (config.value?.overlaidEnabled ?? smMpIbeWidgetConfig.overlaidEnabled),
    integratedEnabled: params.smMpIntegratedEnabled !== undefined
      ? params.smMpIntegratedEnabled === 'true'
      : (config.value?.integratedEnabled ?? smMpIbeWidgetConfig.integratedEnabled),
    highlightColor: params.smMpHighlightColor ?? config.value?.highlightColor ?? smMpIbeWidgetConfig.highlightColor,
    buttonShape: IbeWidgetButtonShape[params.smMpButtonShape as keyof typeof IbeWidgetButtonShape]
      ?? config.value?.buttonShape ?? smMpIbeWidgetConfig.buttonShape,
    iframeEnabled: params.smMpIframeEnabled !== undefined
      ? params.smMpIframeEnabled === 'true'
      : config.value?.iframeEnabled ?? smMpIbeWidgetConfig.iframeEnabled,
  })
}

const fetchConfigFromApi = (config: Ref<Config | undefined>) => {
  overlaidBackgroundColor = IbeWidgetBackgroundColor[config.value!.overlaidBackgroundColor as keyof typeof IbeWidgetBackgroundColor]
  integratedBackgroundColor = IbeWidgetBackgroundColor[config.value!.integratedBackgroundColor as keyof typeof IbeWidgetBackgroundColor]

  setSmMpIbeWidgetConfig({
    buttonShape: IbeWidgetButtonShape[config.value!.buttonShape as keyof typeof IbeWidgetButtonShape],
    overlaidEnabled: config.value!.overlaidEnabled,
    integratedEnabled: config.value!.integratedEnabled,
    highlightColor: config.value!.highlightColor,
    iframeEnabled: config.value!.iframeEnabled,
  })
}

const initializeSmMpIbeWidget = async (widgetId: string) => {
  // Do not initialize the widget if the BEEF URL cannot be derived from script
  if (!deriveBeefUrl()) {
    console.error('The script failed to derive the API URL! Please follow the instructions provided from Direct Bookings page in Multi-Property')
    return
  }

  const params = getUrlSearchParams()
  setSmMpIbeWidgetConfig({
    widgetId: params.smMpWidgetId || widgetId,
  })

  try {
    await fetchPropertyPickerConfig(smMpIbeWidgetConfig.widgetId)
  } catch (err) {
    console.error('The script failed to query the API for the needed data. Please follow the instructions provided from Direct Bookings page in Multi-Property', err)

    return
  }

  const { config, language } = usePropertyPicker()
  await loadLanguageAsync(params.smMpLanguage ?? language?.value ?? 'en')

  if (params.smMpIbeWidgetPreviewMode) {
    fetchConfigFromParams(params, config)
  } else {
    fetchConfigFromApi(config)
  }

  const buttonBorderColor = changeColorBrightness(smMpIbeWidgetConfig.highlightColor, darker50)

  const root = document.documentElement
  root.style.setProperty('--highlight-color', smMpIbeWidgetConfig.highlightColor)
  root.style.setProperty('--dialog-triangle-color', buttonBorderColor)

  setSmMpIbeWidgetConfig({
    backgroundColor: overlaidBackgroundColor,
  })
  const element = getIntegratedModeTagElement()
  if (element) {
    setSmMpIbeWidgetConfig({
      isIntegratedTagExisting: true,
    })

    if (smMpIbeWidgetConfig.integratedEnabled) {
      setSmMpIbeWidgetConfig({
        backgroundColor: integratedBackgroundColor,
      })
    }
  } else {
    embedSmMpIbeWidget()
  }
}

window.initializeSmMpIbeWidget = initializeSmMpIbeWidget

const initializeForDevelopment = () => {
  if (process.env.NODE_ENV === 'development' && process.env.VUE_APP_SKIP_INIT_DEV !== '1') {
    window.initializeSmMpIbeWidget('3ef91708-6aff-5b42-b87c-5ddc08364394')
  }
}

initializeForDevelopment()

export {
  initializeSmMpIbeWidget,
  initializeForDevelopment,
  getIntegratedModeTagElement,
}
