export const defaultParser = 'YYYY-MM-dd'

export default (d: Date): string => {
  const year = d.getFullYear()
  const month = (d.getMonth() + 1).toString().padStart(2, '0')
  const day = d.getDate().toString().padStart(2, '0')
  return `${year}-${month}-${day}`
}

export { default as diffDays } from './diffDays'
export { default as diffMonths } from './diffMonths'
export { default as eachDay } from './eachDay'
export { default as getTodayInTimeZone } from './getTodayInTimeZone'
export { default as isBefore } from './isBefore'
export { default as isValid } from './isValid'
export { default as isWithinRange } from './isWithinRange'
export { default as normalizeDate } from './normalizeDate'
