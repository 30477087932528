import { Spacetime } from 'spacetime'
import isValid, { SpacetimeValidity } from '../isValid'

export default (earlierDate: string|Date, laterDate: string|Date): number => {
  let diff = Number.NaN

  const earlierDateValidity: SpacetimeValidity = isValid(earlierDate)
  const laterDateValidity: SpacetimeValidity = isValid(laterDate)

  if (!earlierDateValidity.isValid || !laterDateValidity.isValid) {
    return diff
  }

  const spacetime2:Spacetime = laterDateValidity.spacetime!
  const spacetime1:Spacetime = earlierDateValidity.spacetime!

  diff = spacetime1.startOf('day').diff(spacetime2.startOf('day'), 'day')
  if (Object.is(diff, -0)) {
    diff = 0
  }
  return diff
}
