import spacetime, { Spacetime } from 'spacetime'
import parseDate from '../parseDate'

export interface SpacetimeValidity {
  spacetime: Spacetime | null
  isValid: boolean
}

export default (date: string | number | Date): SpacetimeValidity => {
  const invalid: SpacetimeValidity = { spacetime: null, isValid: false }
  if (!date) {
    return invalid
  }
  let s: Spacetime = spacetime.now()

  try {
    if (date instanceof Date) {
      if (Number.isNaN(date.getTime())) return invalid
      s = spacetime(date)
    } else if (typeof date === 'string') {
      if (!Number.isNaN(Date.parse(date))) {
        s = parseDate(date)
      } else {
        s = spacetime(date)
        s.epoch = NaN
      }
    } else if (typeof date === 'number') {
      if (!Number.isNaN(date)) {
        s = spacetime(Number(date))
      }
    }
    // if (s === null) return invalid
  } catch (_ex) {
    return invalid
  }
  return {
    spacetime: s,
    isValid: s.isValid(),
  }
}
