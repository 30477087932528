import { render, staticRenderFns } from "./iframe-modal.vue?vue&type=template&id=61b7152a&scoped=true&"
import script from "./iframe-modal.vue?vue&type=script&setup=true&lang=ts&"
export * from "./iframe-modal.vue?vue&type=script&setup=true&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./iframe-modal.vue?vue&type=style&index=0&id=61b7152a&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "61b7152a",
  null
  ,true
)

export default component.exports