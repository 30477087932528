import { render, staticRenderFns } from "./sm-mp-ibe-widget.vue?vue&type=template&id=4a3ccf8f&scoped=true&shadow"
import script from "./sm-mp-ibe-widget.vue?vue&type=script&setup=true&lang=ts&shadow"
export * from "./sm-mp-ibe-widget.vue?vue&type=script&setup=true&lang=ts&shadow"
function injectStyles (context) {
  
  var style0 = require("./sm-mp-ibe-widget.vue?vue&type=style&index=0&id=4a3ccf8f&prod&lang=scss&scoped=true&shadow")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "4a3ccf8f",
  null
  ,true
)

export default component.exports