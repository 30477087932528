import { render, staticRenderFns } from "./custom-html-dropdown.vue?vue&type=template&id=479f7310&scoped=true&"
import script from "./custom-html-dropdown.vue?vue&type=script&setup=true&lang=ts&"
export * from "./custom-html-dropdown.vue?vue&type=script&setup=true&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./custom-html-dropdown.vue?vue&type=style&index=0&id=479f7310&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "479f7310",
  null
  ,true
)

export default component.exports