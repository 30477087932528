import { render, staticRenderFns } from "./primary-button.vue?vue&type=template&id=43bfb9ee&scoped=true&"
import script from "./primary-button.vue?vue&type=script&setup=true&lang=ts&"
export * from "./primary-button.vue?vue&type=script&setup=true&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./primary-button.vue?vue&type=style&index=0&id=43bfb9ee&prod&lang=scss&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "43bfb9ee",
  null
  ,true
)

export default component.exports