import { computed, reactive, readonly } from 'vue'
import { IbeWidgetBackgroundColor, IbeWidgetButtonShape, SmMpIbeWidgetConfig, IbeWidgetRenderMode } from '@/types'
import { isMobileView, isTabletView } from '@/utils/window-utils'

const defaultConfig = {
  widgetId: '',
  isIntegratedTagExisting: false,
  overlaidEnabled: false,
  integratedEnabled: false,
  backgroundColor: IbeWidgetBackgroundColor.light,
  highlightColor: '#FF0000',
  buttonShape: IbeWidgetButtonShape.curved,
  contentWidth: null,
  mobileViewBreakpoint: null,
  iframeEnabled: false,
  isMobile: isMobileView(window),
  isTablet: isTabletView(window),
}

const smMpIbeWidgetConfig = reactive<SmMpIbeWidgetConfig>({ ...defaultConfig })

const widgetRenderMode = computed(() => {
  if (smMpIbeWidgetConfig.integratedEnabled && smMpIbeWidgetConfig.isIntegratedTagExisting) {
    if (smMpIbeWidgetConfig.isMobile) {
      return IbeWidgetRenderMode.overlaid
    }

    return IbeWidgetRenderMode.integrated
  }

  if (smMpIbeWidgetConfig.overlaidEnabled) {
    return IbeWidgetRenderMode.overlaid
  }

  return IbeWidgetRenderMode.none
})

const isIntegratedMode = computed(() => widgetRenderMode.value === IbeWidgetRenderMode.integrated)

const resetSmMpIbeWidgetConfig = () => {
  Object.assign(smMpIbeWidgetConfig, defaultConfig)
}

const setSmMpIbeWidgetConfig = (newConfig: Partial<SmMpIbeWidgetConfig>) => {
  Object.assign(smMpIbeWidgetConfig, newConfig)
}

export default () => ({
  smMpIbeWidgetConfig: readonly(smMpIbeWidgetConfig),
  widgetRenderMode,
  isIntegratedMode,
  resetSmMpIbeWidgetConfig,
  setSmMpIbeWidgetConfig,
})
