import { ref } from 'vue'
import { Property, Config } from '@/types'

const properties = ref<Property[]>([])
const config = ref<Config>()
const language = ref<string>('')

const deriveBeefUrl = () => {
  const fallbackUrl = 'https://nxs-dev-mp-ibe.dev.siteminderlabs.com/ibe-widget-beef/api/v1'
  const scriptTag = document.querySelector('script[src$="sm-mp-ibe-widget.min.js"') as HTMLScriptElement

  if (!scriptTag) {
    // If loaded via webpack dev server, there will be no matching script tag
    if (process.env.NODE_ENV === 'development') {
      return fallbackUrl
    }

    // The script might have been renamed/moved
    return null
  }

  const scriptUrl = scriptTag.src
  const { origin, hostname } = new URL(scriptUrl)

  if (hostname === 'localhost') {
    return fallbackUrl
  }

  const regex = /^mp-widgets.((dev|preprod).siteminderlabs.com|siteminder.(com|systems))$/
  if (!regex.test(hostname)) {
    // The script is not referenced from our CDN
    return null
  }

  return `${origin}/ibe-widget-beef/api/v1`
}

const fetchPropertyPickerConfig = async (widgetId: string) => {
  const beefUrl = deriveBeefUrl()
  const requestUrl = `${beefUrl}/property-pickers/${widgetId}`

  const response = await fetch(requestUrl)
  const data = await response.json()

  if (data.errors) {
    throw data.errors
  }

  properties.value = data.properties
  config.value = data.config
  language.value = data.defaultLanguageLocale
}

const usePropertyPicker = () => ({
  properties,
  config,
  language,
})

export {
  deriveBeefUrl,
  fetchPropertyPickerConfig,
  usePropertyPicker,
}
