import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from '@/lang/mp-ibe-ibe-widgets-en.json'

export const languageFilePrefix = 'mp-ibe-ibe-widgets'

export const supportedLocales = [
  'de',
  'en',
  'es',
  'fr',
  'id',
  'it',
  'pt',
  'th',
]

export const languageMap = {
  de: 'de',
  en: 'en',
  es: 'es',
  fr: 'fr',
  id: 'id',
  it: 'it',
  pt: 'pt',
  th: 'th',
  /* eslint-disable-next-line @typescript-eslint/naming-convention */
  en_gb: 'en',
  'en-gb': 'en',
} as Record<string, string>

Vue.use(VueI18n)
export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
  },
  silentFallbackWarn: true,
})

const loadedLanguages = ['en']

const setI18nLanguage = (lang: string) => {
  i18n.locale = lang
  document.querySelector('html')!.setAttribute('lang', lang)
  return lang
}

const loadLanguage = async (lang: string) => {
  const mpLocaleTranslations = await import(`@/lang/${languageFilePrefix}-${lang}.json`)

  i18n.setLocaleMessage(lang, mpLocaleTranslations)
  setI18nLanguage(lang)
}

export const loadLanguageAsync = async (language: string) => {
  const lang = languageMap[language]

  if (i18n.locale === lang) {
    return
  }

  if (loadedLanguages.includes(lang)) {
    return loadLanguage(lang)
  }

  if (supportedLocales.includes(lang)) {
    await loadLanguage(lang)
    loadedLanguages.push(lang)
    return
  }

  if (lang !== 'en_gb' && lang !== 'en-gb') {
    console.error(`Unsupported locale "${language}". Falling back to English (UK)`)
  }

  return loadLanguage('en')
}
