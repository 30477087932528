import { computed } from 'vue'
import { i18n } from '@/services/i18n'

export const useTranslate = (path?: string) => {
  const t = (key: string, ...args: any[]) => {
    const combinedPath = `${path}.${key}`

    if (i18n.te(combinedPath, 'en')) {
      return i18n.t(combinedPath, ...args).toString()
    }

    return combinedPath
  }
  const tc = (key: string, ...args: any[]) => {
    const combinedPath = `${path}.${key}`

    if (i18n.te(combinedPath, 'en')) {
      return i18n.tc(combinedPath, ...args)
    }

    return combinedPath
  }

  const locale = computed(() => i18n.locale)

  return {
    t,
    tc,
    locale,
  }
}
